
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import ham from "../assets/Menu1.png"
import iks from "../assets/iks.png"
import logout from "../assets/logout.png"
import Logo from "../assets/LOGO.png";
import Log from "../assets/logo_u.png";
import axios from "axios";
import { GlobalStates } from "../GlobalStates";
import { Cookie } from "../clases/CookieClass";
import PrimaryButton from "../Components/Button/PrimaryButton";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SingleSelect from "../Components/Select/SingleSelect"
import MainNavigation from "./MobNav";
import { elementAcceptingRef } from "@mui/utils";
import KalbuPasirinkimas from "../Components/KalbuPasirinkimas";
import { useTranslation } from "react-i18next";


const Navigation = () => {
    const { t, i18n } = useTranslation();
    const { user, setUser, tasks, } = useContext(GlobalStates)
    const mobileNav = useRef(null);
    const cookie = useMemo(() => new Cookie('Formu'), []);

    const [open, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthorized = useMemo(() => !!user && !!cookie.getCookie(), [user, cookie]);
    const [active, setActive] = useState('/')

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})
    const kalbos = new Cookie('kalbai')

    const hamburger = <div className="ham"
        onClick={() => {
            setOpen(!open);
        }}>
        <img src={ham} alt="ham" />
    </div>
    const atsijungti = () => {
        cookie.removeCookie()
        setUser(undefined);
        navigate('/');
    }

    useEffect(() => {
        setActive(window.location.pathname)
    }, [location]);

    const linkas = (x, text) => {
        return (
            <Link key={x} className={active == '/' + x ? 'active navItem' : 'navItem'} to={"/" + x}>{text}</Link>
        )
    }
    const linkasMob = (x, text) => {
        return (
            <Link onClick={() => {
                // mobileNav.current.style.display = 'none';
            }} key={x} className={active == '/' + x ? 'active navItem' : 'navItem'} to={"/" + x}>{text} </Link>
        )
    }
    const linkaiAdmin = t('linkaiAdmin')
    const linkaiNeAdmin = t('linkaiNeAdmin')
    const linkaiAdmiText = t('linkaiAdminText')
    const linkaiNeAdminText = t('linkaiNeAdminText')

    return (
        isAuthorized ?
            <div className={location.pathname.includes('Formos') ? 'displayNone' : "navBar"}>

                {/*CUSTOM HAMBURGER*/}
                {/* <div className="ham"
                    onClick={() => {
                        mobileNav.current.style.display = 'block';
                    }}
                ><img src={ham} alt="Menu" /></div> */}

                {/* MOBILE NAV */}
                {
                    user.adminConfimed ? (

                        <nav key='navigationConainerMob' className="navContainerMob"  >
                            <MainNavigation

                                // ref={mobileNav}
                                element={
                                    <nav className="column">{linkaiAdmin.map((e, i) => (linkasMob(e, linkaiAdmiText[i])))}</nav>
                                }
                            />
                        </nav>
                    ) : (
                        <nav key='navigationConainerMob' className="navContainerMob">
                            <MainNavigation
                                // ref={mobileNav}
                                element={<nav className="column">{linkaiNeAdmin.map((e, i) => (linkasMob(e, linkaiNeAdminText[i])))}</nav>

                                }
                            />
                        </nav>

                    )

                }

                <a key={'Home'} className={"navLogo"} href="https://diversity.lt" target='_blank' rel="noreferrer">{<img src={Logo} alt="Logo" />}</a>
                <KalbuPasirinkimas
                    options={t('kalba')}
                    optionsText={t('kalba_text')}
                    className='kalbaNavMobile'
                />
                <div className="NavBarDesine">
                    {
                        user.adminConfimed ? (

                            <nav key='navigationConainer' className="navContainer" >
                                {linkaiAdmin.map((e, i) => (linkas(e, linkaiAdmiText[i])))}

                            </nav>
                        ) : <nav key='navigationConainer' className="navContainer" >
                            {linkaiNeAdmin.map((e, i) => (linkas(e, linkaiNeAdminText[i])))}
                        </nav>
                    }
                    <div className="navBarDesinesDesine">
                        <KalbuPasirinkimas
                            options={t('kalba')}
                            optionsText={t('kalba_text')}
                        />
                        {/* <PrimaryButton
                            text='apklausu_rezultatai '
                        /> */}
                        {/* <SingleSelect
                            sx1={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                            sx0={{ minWidth: 0 }}
                            label={i18n.language}
                            classNameSelectLabel='kalbaSelectLabel'
                            options={t('kalba')}
                            optionsText={t('kalba_text')}
                            sx={{ m: 1, width: 65 }}
                            className='kalba'
                            set={(l, v) => {
                                i18n.changeLanguage(l)
                                kalbos.setCookie(l)
                                window.location.reload(false);
                            }}
                            size='small'
                        /> */}
                        <Link key={'Profilis'} className={active == '/Profilis' ? 'active navItem column profilis' : 'navItem column profilis'} to="/Profilis">
                            <PersonIcon />
                            {t('profilis')}
                        </Link>


                        <a key={'atsijungti3'} className={active == '/' ? 'active navItem' : 'navItem'} onClick={atsijungti}>
                            <LogoutIcon />
                        </a>

                    </div>
                </div>


            </div > : ''

    )
}

export default Navigation

